@font-face {
  font-weight: normal;
  font-family: "circe";
  font-style: normal;
  font-display: swap;
  src: url("/_fonts/crc55.woff2") format("woff2"),
    url("/_fonts/crc55.woff") format("woff");
}

@font-face {
  font-weight: normal;
  font-family: "circe-light";
  font-style: normal;
  font-display: swap;
  src: url("/_fonts/crc35.woff2") format("woff2"),
    url("/_fonts/crc35.woff") format("woff");
}

html {
  box-sizing: border-box;
  font-size: 15px;
  line-height: 1.4;

  @media screen and (min-width: @tablet) {
    font-size: 16px;
    line-height: 1.6;
  }
}

*,
::after,
::before {
  box-sizing: inherit;
}

body {
  font-family: "circe", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;

  color: @text-color;
  background-color: @bg-color;
}

.visually-hidden[type="radio"],
.visually-hidden[type="checkbox"],
.visually-hidden:not(:focus):not(:active) {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  width: 100%;
  max-width: 450px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: @tablet) {
    max-width: 600px;
  }
  
  @media screen and (min-width: @desktop) {
    max-width: @desktop;
    padding-left: 90px;
    padding-right: 90px;
  }
}

img {
  display: block;
  border: 0;
  max-width: 100%;
}