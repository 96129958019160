.slider {
  position: relative;
  width   : calc(100% + 40px);
  margin-left: -20px;
  overflow: hidden;
  margin-bottom: 2rem;

  @media screen and (min-width: @desktop) {
    width: 100%;
    margin-left: 0;
  }
}

.slider__track {
  .ul-reset();

  display: flex;
  transition-property: transform;
  transition-duration: 300ms;
}

.slider__item {
  width      : 100%;
  flex-shrink: 0;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.slider__dots {
  .ul-reset();

  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slider__dot {
    width: 10px;
    height: 10px;

    margin: 0 3px;

    background-color: transparent;
    background-image: url(../img/dot.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    &--active {
      background-image: url(../img/dot-active.svg);
    }
  }

.slider__button {
  .button-reset();

  display: none; // temporary

  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  top: 50%;
  transform: translateY(-50%);

  width: 30px;
  height: 50px;

  &--prev {
    .arrow(left, @width: 20px);
    left: 10px;
  }

  &--next {
    .arrow(right, @width: 20px);
    right: 10px;
  }
}
