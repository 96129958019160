.parking {
  &__text {
    margin-top : 0;
    font-family: "circe-light", "Arial", sans-serif;
  }

  &__title {
    position: relative;
    padding: 0 10px;
    margin-bottom: 10px;
  }

  &__title::before {
    content: "";
    display: block;
    background-image: url(../img/parking.svg);
    width: 50px;
    height: 50px;
    margin: auto;
    margin-bottom: 20px;

    @media screen and (min-width: @desktop) {
      content: none;
    }
  }

  &__inner {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

}


.parking__column {
  margin-top: 2rem;

  @media screen and (min-width: @tablet) {
    position  : relative;
    margin-top: 0;
  }
}

.parking__column::before {
  @media screen and (min-width: @tablet) {
    content    : "";
    position   : absolute;
    left       : -1.3rem;
    top        : 0;
    bottom     : 0;
    border-left: 1px dotted #777777;
  }
}

.parking__map {
  margin-left: -20px;
  width      : ~"calc(100% + 40px)";
  max-width  : ~"calc(100% + 40px)";
  cursor     : pointer;

  @media screen and (min-width: @tablet) {
    width      : 100%;
    margin-left: 0;
  }
}

.parking__toggle {
  .button-reset();
  .arrow(bottom, @width: 1.5rem);

  display     : block;
  position: relative;
  margin-left : auto;
  margin-right: auto;
  margin-top: -1rem;
  width       : 2rem;
  height      : 2rem;

  &--opened {
    .arrow(top, @width: 1.5rem);
  }
}
