.header {
  text-align: center;
  padding: 2rem 0;
}

.logo {
  margin: auto;
  width: 100%;
  max-width: 440px;
}

.logo-title {
  font-size: 1.2rem;
  margin: 0;
  margin-top: 1rem;
}
