.map {}

.map__text {
  background-color: @bg-color;
  padding         : @padding-value;
  margin          : 0;
  margin-left     : -@padding-value-px;
  margin-right    : -@padding-value-px;
  font-size       : 1.2rem;
  text-align      : center;

  @media screen and (min-width: @desktop) {
    margin-left : 0;
    margin-right: 0;
  }
}

.map__list {
  @media screen and (min-width: @desktop) {
    display        : flex;
    justify-content: space-between;
  }
}

.map__item {
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  padding        : 0;
  margin         : 0;

  img {
    margin-left : -@padding-value-px;
    margin-right: -@padding-value-px;
    width       :~"calc(100% + 2 * @{padding-value-px})";
    max-width   :~"calc(100% + 2 * @{padding-value-px})";
    border      : 1px solid #C8CACB;
  }

  @media screen and (min-width: @desktop) {
    width :~"calc(50% - @{padding-value} / 2)";

    img {
      margin   : 0;
      width    : 100%;
      max-width: 100%;
    }
  }
}

.map__header {
  position         : relative;
  text-transform   : uppercase;
  font-size        : 1.2rem;
  font-weight      : bold;
  padding          : 0;
  margin           : 0;
  padding-left     : 3rem;
  padding-top      : @padding-value;
  // padding-bottom:~"calc(@{padding-value} / 2)";

  &::before {
    content            : "–";
    position           : absolute;
    width              : 2.8rem;
    height             : 1.5rem;
    left               : 0;
    text-align         : right;
    background-position: 0 0;
    background-size    : contain;
    background-repeat  : no-repeat;
  }

  &--1::before {
    background-image: url(../img/parking-1.svg);
  }

  &--2::before {
    background-image: url(../img/parking-2.svg);
  }

  @media screen and (min-width: @desktop) {
    &::before {
      height: 1.7rem;
    }
  }
}

.map__image {}
