.subscribe {
  position: relative;

  @media screen and (min-width: @desktop) {
    padding-left: 5rem;
  }
}

.subscribe__button {
  .button();
}

.subscribe__name,
.subscribe__email {
  display       : inline-block;
  vertical-align: middle;
  font-size     : 1rem;
  padding       : 0.5rem 0.5rem 0.25rem;
  width         : 100%;
  height        : 2rem;
}

.subscribe__agreement {
  position    : relative;
  padding-left: 2rem;

  &--attention::after {
    content  : "Для подписки необходимо поставить галочку";
    position : absolute;
    left     : 0;
    bottom   : -1rem;
    font-size: 0.7rem;
    color    : red;
  }
}

.subscribe__agreement::before {
  content     : "";
  position    : absolute;
  left        : 0;
  width       : 1.25rem;
  height      : 1.25rem;
  margin-right: 0.5rem;

  border: 2px solid #000;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.subscribe__checkbox:focus~.subscribe__agreement::before {
  border: 4px solid #000;
}

.subscribe__checkbox:checked~.subscribe__agreement::before {
  background-color: #000;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K);
}

.subscribe__view-rules {
  .button-reset();
  text-decoration: underline;
  cursor         : pointer;

  &:hover {
    opacity: 0.7;
  }
}

.subscribe__row {
  display        : flex;
  justify-content: space-between;

  @media screen and (min-width: @desktop) {
    align-items: center;
  }
}

.subscribe__desktop-row {
  display       : flex;
  flex-direction: column;
  align-items   : center;

  &>* {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: @desktop) {
    flex-direction : row;
    justify-content: space-between;

    &>* {
      margin-bottom: 0;
    }
  }
}
