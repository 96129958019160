.footer {
  padding-bottom: 2rem;
}

.footer__text {
  text-align: center;
  margin-top: 0;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media screen and (min-width: @desktop) {
    text-align: left;
  }
}
