.subdivision {
  margin-bottom: 2rem;

  &--other {
    margin-top: 2.2rem;
  }

  h3 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: 1.2;
    margin: 1rem 0;
  }
}

.subdivision:last-child {
  @media screen and (min-width: @desktop) {
    margin-bottom: 0;
  }
}

.subdivision__more {
  .button-reset();

  display: block;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none;
  font-size: 0.9rem;
  color: inherit;

  @media screen and (min-width: @desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.subdivision__vip-button {
  color: #876955;
}

.subdivision__jewellery-button {
  display: block;

  @media screen and (min-width: @desktop) {
    display: none;
  }
}
