.boutique-list {
  .ul-reset();
}

.boutique {
  display        : flex;
  justify-content: space-between;
  align-items    : flex-start;
  border-bottom  : 1px solid transparent;
  margin-bottom  : 10px;

  &--long {
    @media screen and (min-width: @desktop) {
      flex-wrap: wrap;

      .boutique__phone {
        width     : 100%;
        text-align: right;
      }
    }
  }

  a {
    color          : inherit;
    text-decoration: none;
    line-height: 1;
  }

  &:hover {
    border-bottom: 1px solid #000000;
  }

  i {
    font-size: 0.9em;
  }
}
