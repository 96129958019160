.section {
  margin-bottom: 3rem;

  &--rules {
    margin-bottom: 0;
  }

  &--parking {
    border: 1px solid #000;
    padding: 2rem 2rem 1.5rem 2rem;
  }
}

.section__title {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  margin-bottom: 1rem;
}

.section__subtitle {
  font-size: 1.2rem;
  text-align: center;
  font-weight: normal;
  margin: 0;
  margin-bottom: 1rem;
}
