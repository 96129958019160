.rules {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.7);
  transition-property: opacity;
  transition-duration: 500ms;

  &--visible {
    visibility: visible;
    opacity: 1;
  }
}

.rules__inner {
  position: relative;
  padding: 2rem;
  margin: 1rem;
  background-color: #fff;
  width: 80vw;
  height: 80vh;
  overflow-y: auto;
}

.rules__title {

}

.rules__text {

}

.rules__close {
  text-align: center;

  button {
    .button();
  }
}

.rules__image {
  display: flex;
  img {
    object-fit: contain;
  }
}
