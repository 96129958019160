.columns {
  .ul-reset();

  @media screen and (min-width: @desktop) {
    display        : flex;
    justify-content: space-between;

    &--reverse {
      flex-direction: row-reverse;
    }
  }
}

.columns--2 {
  @media screen and (min-width: @desktop) {
    &>.column {
      width: ~"calc(50% - 1rem)";
    }
  }
}

.columns--1-1 {
  @media screen and (min-width: @desktop) {
    &>.column:first-child {
      width: ~"calc(50% - 1rem)";
    }

    &>.column:last-child {
      width: ~"calc(50% - 1rem)";
    }
  }
}

.columns--1-2 {
  @media screen and (min-width: @desktop) {
    &>.column:first-child {
      width: ~"calc(33% - 1rem)";
    }

    &>.column:last-child {
      width: ~"calc(66% - 1rem)";
    }
  }
}

.columns--2-1 {
  @media screen and (min-width: @desktop) {
    &>.column:first-child {
      width: ~"calc(66% - 1rem)";
    }

    &>.column:last-child {
      width: ~"calc(33% - 1rem)";
    }
  }
}

.columns--3 {
  @media screen and (min-width: @desktop) {
    &>.column {
      width: ~"calc(33% - 2rem)";
    }
  }
}
