.main-message {
  position        : absolute;
  left            : 50%;
  top             : 50%;
  transform       : translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding         : 3rem;
  font-size       : 1.25rem;
  box-shadow      : 0 0 15px rgba(0, 0, 0, 0.5);
  min-width       : 300px;
  text-align      : center;

  &--success {
    color: green;
  }

  &--error {
    color: red;
  }
}
